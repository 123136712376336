.adminPanel {
  display: flex;
  height: 100vh;
  background-color: white;
}

.sidebar {
  width: 250px;
  background-color: dodgerblue;
  color: white;
  padding: 20px;
}

.sidebar h1 {
  margin-bottom: 30px;
  text-align: center;
}

.sidebar nav {
  display: flex;
  flex-direction: column;
}

.sidebar button {
  background-color: transparent;
  color: white;
  border: none;
  padding: 10px 15px;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s;
}

.sidebar button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.sidebar button.active {
  background-color: rgba(255, 255, 255, 0.3);
}

.content {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
}