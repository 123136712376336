.usersManagement {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.userTable {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.userTable th, 
.userTable td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.userTable th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.userInfo {
  display: flex;
  flex-direction: column;
}

.actionButtons {
  display: flex;
  gap: 10px;
}

.deleteButton, 
.viewButton {
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.deleteButton {
  background-color: #ff4d4d;
  color: white;
}

.viewButton {
  background-color: #4CAF50;
  color: white;
}

.deleteButton:hover {
  background-color: #ff3333;
}

.viewButton:hover {
  background-color: #45a049;
}

.deleteButton:disabled,
.viewButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.errorContainer {
  color: #d9534f;
  padding: 20px;
  background-color: #f2dede;
  border-radius: 5px;
  margin: 20px 0;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 300px;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

.userStats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  color: #555;
}

.paginationControls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
}

.paginationControls button {
  padding: 8px 16px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
}

.paginationControls button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}