.bottomNav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.05);
  z-index: 1000;
  padding-bottom: env(safe-area-inset-bottom, 0px);
  height: calc(60px + env(safe-area-inset-bottom, 0px));
}

.navItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #000000;  /* Changed to black */
  padding: 8px;
  transition: all 0.2s;
  flex: 1;
  max-width: 100px;
}

.navItem span {
  font-size: 12px;
  margin-top: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  color: #000000;  /* Explicitly set to black */
}

.navItem.active {
  color: #1E90FF;  /* Dodger blue */
}

.navItem:hover {
  color: #1E90FF;  /* Dodger blue */
  background-color: rgba(30, 144, 255, 0.1);  /* Slight dodger blue tint */
}

/* Loading animation */
@keyframes loading {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #000000;  /* Black loading spinner */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: loading 1s linear infinite;
}

@media screen and (max-width: 360px) {
  .navItem span {
    font-size: 10px;
  }
  
  .bottomNav {
    height: 56px;
    height: calc(56px + env(safe-area-inset-bottom, 0px));
  }
}
