.pageContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 60px;
  overflow: hidden;
}

.header {
  padding: 1.5rem 1.25rem;
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
}

.header h1 {
  font-size: 1.75rem;
  font-weight: 700;
  color: #000000;
  margin: 0;
}

.likedContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 60px;
  position: relative;
  min-height: 0;
}

.likedLayout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 0;
}

.likedMain {
  flex: 1;
  padding: 1.25rem;
  background-color: #f5f5f5;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #c2c2c2 #f5f5f5;
  position: relative;
  min-height: 0;
  -webkit-overflow-scrolling: touch;
}

.likedMain::-webkit-scrollbar {
  width: 8px;
}

.likedMain::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.likedMain::-webkit-scrollbar-thumb {
  background-color: #c2c2c2;
  border-radius: 4px;
}

.topNavbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  z-index: 50;
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

.topNavContent {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topNavTitle {
  font-family: 'lineto-circular', sans-serif;
  font-size: 1.5rem;
  color: #000000;
  flex-grow: 1;
}

.topNavActions {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.settingsButton {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  color: #000000;
  transition: background-color 0.2s ease;
  border-radius: 50%;
}

.settingsButton:hover {
  background-color: #f1f5f9;
}

.settingsDropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  width: 200px;
  z-index: 20;
}

.logoutButton {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  background: none;
  border: none;
  padding: 0.75rem 1rem;
  text-align: left;
  cursor: pointer;
  border-radius: 8px;
  font-size: 0.9375rem;
  color: #000000;
  transition: background-color 0.2s ease;
}

.logoutButton:hover {
  background-color: #f1f5f9;
}

.logoutButton svg {
  color: #ef4444;
}

.searchBox {
  position: relative;
  margin-bottom: 1.25rem;
  width: 100%;
  max-width: 600px;
}

.searchBox svg {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #666666;
  pointer-events: none;
}

.searchBox input {
  width: 100%;
  height: 44px;
  padding: 0 1rem 0 2.75rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #ffffff;
  font-size: 0.9375rem;
  color: #000000;
  transition: all 0.2s;
}

.searchBox input:focus {
  outline: none;
  border-color: #1E90FF;
  box-shadow: 0 0 0 3px rgba(30, 144, 255, 0.1);
}

.likesHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.likesHeader h2 {
  font-size: 1.125rem;
  font-weight: 600;
  color: #000000;
  margin: 0;
}

.likesHeader span {
  font-size: 0.875rem;
  color: #666666;
}

.usersList {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.userCard {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background-color: #ffffff;
  border: 1.5px solid #e0e0e0;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s;
  width: 100%;
  text-align: left;
}

.userCard:hover,
.userCard:active {
  background-color: #f9f9f9;
  border-color: #1E90FF;
}

.userAvatarCircle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #1E90FF;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: 600;
  font-size: 1.25rem;
  flex-shrink: 0;
}

.userInfo {
  flex: 1;
  min-width: 0;
}

.userInfo .name {
  font-size: 1rem;
  font-weight: 500;
  color: #000000;
  margin: 0 0 0.25rem 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.userInfo .status {
  font-size: 0.875rem;
  color: #666666;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.likeIcon {
  flex-shrink: 0;
  color: #1E90FF;
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
  color: #666666;
  text-align: center;
}

.emptyStateSubtext {
  font-size: 0.875rem;
  color: #999999;
  margin: 0;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: rgba(255, 255, 255, 0.9);
}

.loadingSpinner {
  animation: spin 1s linear infinite;
  width: 32px;
  height: 32px;
  border: 3px solid #e2e8f0;
  border-top-color: #000000;
  border-radius: 50%;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@media screen and (max-width: 1024px) {
  .searchBox {
    max-width: 100%;
  }

  .header {
    padding: 1.25rem 1rem;
  }

  .header h1 {
    font-size: 1.5rem;
  }

  .likedMain {
    padding: 1rem;
  }
}

@media screen and (max-width: 640px) {
  .searchBox {
    margin-bottom: 1rem;
  }

  .searchBox input {
    height: 40px;
    font-size: 0.875rem;
  }

  .userCard {
    padding: 0.75rem;
    gap: 0.75rem;
  }

  .userAvatarCircle {
    width: 42px;
    height: 42px;
    font-size: 1rem;
  }

  .userInfo .name {
    font-size: 0.9375rem;
  }

  .userInfo .status {
    font-size: 0.8125rem;
  }
}

@media screen and (max-width: 375px) {
  .userCard {
    padding: 0.5rem;
    gap: 0.5rem;
  }

  .userAvatarCircle {
    width: 36px;
    height: 36px;
    font-size: 0.875rem;
  }
}

@media (prefers-reduced-motion: reduce) {
  .loadingSpinner {
    animation: none;
  }
}
