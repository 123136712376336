.profileWrapper {
  min-height: 100vh;
  background-color: #f8fafc;
  padding: 2rem 1rem;
}

.profileContainer {
  width: 100%;
  max-width: 800px;
  background: #ffffff;
  border-radius: 24px;
  padding: 3rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
              0 2px 4px -1px rgba(0, 0, 0, 0.06);
  margin: 0 auto;
}

.profileHeader {
  text-align: center;
  margin-bottom: 3rem;
}

.profilePhotoSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.profilePhoto {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.profilePhoto img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.photoPlaceholder {
  width: 100%;
  height: 100%;
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4rem;
  font-weight: 600;
  color: white;
}

.profileHeader h2 {
  font-size: 2rem;
  font-weight: 700;
  color: #1e293b;
  margin: 0;
}

.subtitle {
  color: #64748b;
  font-size: 1.125rem;
  margin: 0;
}

.infoSection {
  margin-top: 3rem;
}

.infoGroup {
  margin-bottom: 2.5rem;
}

.infoGroup h3 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 1rem;
}

.infoGroup p {
  font-size: 1rem;
  line-height: 1.6;
  color: #334155;
  white-space: pre-wrap;
}

.interestsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
}

.interestTag {
  padding: 0.625rem 1rem;
  background: #f8fafc;
  border: 1.5px solid #e2e8f0;
  border-radius: 24px;
  font-size: 0.875rem;
  color: #1e293b;
}

.gallerySection {
  margin-top: 3rem;
  padding-top: 3rem;
  border-top: 1.5px solid #e2e8f0;
}

.gallerySection h3 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 1.5rem;
}

.galleryGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 1.5rem;
}

.galleryItem {
  aspect-ratio: 1;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.galleryItem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.galleryItem:hover img {
  transform: scale(1.05);
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.loadingSpinner {
  animation: spin 1s linear infinite;
  width: 32px;
  height: 32px;
}

.errorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 2rem;
}

.errorMessage {
  background-color: rgba(239, 68, 68, 0.1);
  color: #ef4444;
  padding: 1.5rem 2rem;
  border-radius: 12px;
  font-weight: 500;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .profileContainer {
    padding: 2rem 1.5rem;
    border-radius: 16px;
    margin: 0;
  }

  .profileHeader h2 {
    font-size: 1.75rem;
  }

  .subtitle {
    font-size: 1rem;
  }

  .profilePhoto {
    width: 120px;
    height: 120px;
  }

  .photoPlaceholder {
    font-size: 3rem;
  }

  .galleryGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .gallerySection {
    margin-top: 2rem;
    padding-top: 2rem;
  }
}

@supports(padding: max(0px)) {
  .profileContainer {
    padding-left: max(1.5rem, env(safe-area-inset-left));
    padding-right: max(1.5rem, env(safe-area-inset-right));
  }
}