.fullPageWrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #ffffff;
  padding: 0;
  margin: 0;
  position: relative;
}

.progressIndicator {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 20px 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.progressDot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #e2e8f0;
  transition: all 0.3s ease;
}

.activeDot {
  background-color: #1E90FF;
  width: 20px;
  border-radius: 5px;
}

.profileCompletionHeader {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 10;
}

.profileCompletionHeader h1 {
  font-size: 1.75rem;
  color: #000000;
  font-weight: 600;
}

.stepWrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 120px 20px 20px;
}

.stepContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  max-width: 500px;
  margin: 0 auto;
  width: 100%;
}

.stepContent h1 {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 20px;
  color: #000000;
}

.input {
  width: 100%;
  padding: 15px;
  border: 1.5px solid #1E90FF;
  border-radius: 12px;
  background: #ffffff;
  font-size: 1rem;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #000000;
}

.fieldDescription {
  font-size: 0.8rem;
  color: #333333;
  margin-top: 8px;
  text-align: left;
  width: 100%;
}

.mandatoryIndicator {
  color: red;
  margin-right: 5px;
}

.uploadSection {
  width: 100%;
}

.uploadContainer {
  display: block;
  width: 100%;
}

.hiddenInput {
  display: none;
}

.uploadPlaceholder {
  width: 100%;
  padding: 20px;
  border: 2px dashed #1E90FF;
  border-radius: 12px;
  text-align: center;
  color: #000000;
  cursor: pointer;
}

.uploadPlaceholder:hover {
  background-color: rgba(30, 144, 255, 0.05);
}

.guploadPlaceholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  color: #64748b;
}

.navigationContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  box-shadow: none;
  z-index: 20;
}

.skipButton {
  background: none;
  border: none;
  color: #333333;
  font-size: 1rem;
  cursor: pointer;
  padding: 10px;
  transition: color 0.3s ease;
  margin-right: auto;
}

.skipButton:hover {
  color: #000000;
}

.skipButton:disabled {
  color: #cccccc;
  cursor: not-allowed;
}

.nextButton {
  background: #1E90FF;
  color: #000000;
  border: none;
  padding: 12px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: auto;
}

.nextButton:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.locationContainer {
  width: 100%;
}

.suggestionList {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #1E90FF;
  border-radius: 8px;
  margin-top: 10px;
}

.suggestionItem {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: #000000;
}

.suggestionItem:hover {
  background-color: rgba(30, 144, 255, 0.1);
}

.interestsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.selectedInterests {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
}

.selectedInterestChip {
  background-color: rgba(30, 144, 255, 0.1);
  padding: 8px 12px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  color: #000000;
}

.interestGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.interestItem {
  background-color: rgba(30, 144, 255, 0.1);
  padding: 12px;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #000000;
}

.interestItem:hover {
  background-color: rgba(30, 144, 255, 0.2);
}

.selectedInterest {
  background-color: #1E90FF;
  color: #000000;
}

.galleryUploadContainer {
  margin: 1.5rem 0;
}

.galleryGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 1rem;
}

.galleryItem {
  position: relative;
  aspect-ratio: 1;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(30, 144, 255, 0.2);
}

.galleryItem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.galleryItem:hover img {
  transform: scale(1.05);
}

.removeImage {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: rgba(0, 0, 0, 0.6);
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease;
  opacity: 0;
}

.galleryItem:hover .removeImage {
  opacity: 1;
}

.removeImage:hover {
  background: rgba(239, 68, 68, 0.9);
  transform: scale(1.05);
}

.galleryUpload {
  aspect-ratio: 1;
  border: 2px dashed #1E90FF;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  background: #ffffff;
}

.galleryUpload:hover {
  background: rgba(30, 144, 255, 0.05);
}

.spinnerMedium {
  animation: spin 1s linear infinite;
  width: 24px;
  height: 24px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-top-color: #000000;
  border-radius: 50%;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@media screen and (max-width: 1200px) {
  .stepContent {
    max-width: 90%;
    padding: 120px 15px 20px;
  }

  .navigationContainer {
    max-width: 100%;
    padding: 15px;
  }

  .interestGrid {
    grid-template-columns: repeat(3, 1fr);
  }

  .galleryGrid {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
}

@media screen and (max-width: 992px) {
  .profileCompletionHeader h1 {
    font-size: 1.5rem;
  }

  .stepContent h1 {
    font-size: 1.25rem;
  }

  .interestGrid {
    grid-template-columns: repeat(2, 1fr);
  }

  .input {
    padding: 12px;
    font-size: 0.95rem;
  }

  .navigationContainer {
    padding: 10px;
  }
}

@media screen and (max-width: 768px) {
  .fullPageWrapper {
    padding: 0 5px;
  }

  .stepWrapper {
    padding: 100px 10px 20px;
  }

  .progressIndicator {
    gap: 5px;
  }

  .progressDot {
    width: 8px;
    height: 8px;
  }

  .activeDot {
    width: 16px;
  }

  .interestGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }

  .interestItem {
    padding: 10px;
  }

  .galleryGrid {
    grid-template-columns: repeat(3, 1fr);
    gap: 0.75rem;
  }
}

@media screen and (max-width: 480px) {
  .stepContent {
    padding: 80px 5px 15px;
  }

  .input {
    padding: 10px;
    font-size: 0.9rem;
  }

  .profileCompletionHeader h1 {
    font-size: 1.3rem;
  }

  .stepContent h1 {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 375px) {
  .stepContent {
    padding: 70px 3px 10px;
  }

  .input {
    padding: 8px;
    font-size: 0.85rem;
  }

  .interestGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 6px;
  }

  .navigationContainer {
    padding: 8px;
  }
}

@supports (-webkit-touch-callout: none) {
  .input {
    -webkit-appearance: none;
    border-radius: 12px;
  }
}

@media screen and (-ms-high-contrast: active) {
  .input, .nextButton, .interestItem {
    border: 2px solid currentColor;
  }
}

@media (prefers-reduced-motion: reduce) {
  * {
    transition: none !important;
    animation: none !important;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .input, .galleryUpload {
    border-width: 1px;
  }
}
