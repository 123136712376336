.discoverWrapper {
  width: 100%;
  min-height: 100vh;
  background-color: #f8fafc;
  padding: 80px 16px 80px;
  box-sizing: border-box;
  overflow-x: hidden;
}

.discoverContainer {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.filterSection {
  display: flex;
  justify-content: flex-end;
  padding: 0 1rem;
}

.filterButton {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  color: #000000;
  transition: background-color 0.2s ease;
  border-radius: 50%;
}

.filterButton:hover {
  background-color: #f1f5f9;
}

.profileCard {
  background: white;
  border-radius: 32px;
  overflow: hidden;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.imagesScrollContainer {
  display: flex;
  width: 100%;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
}

.imagesScrollContainer::-webkit-scrollbar {
  display: none;
}

.profileCarouselImage {
  width: 100%;
  height: 500px;
  object-fit: cover;
  scroll-snap-align: center;
  flex-shrink: 0;
}

.profileInfo {
  padding: 1.5rem;
}

.profileName {
  font-size: 1.5rem;
  font-weight: 700;
  color: #000000;
  margin-bottom: 0.5rem;
}

.profileDetails {
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.detailItem {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #000000;
}

.bioSection {
  margin-bottom: 1.5rem;
  color: #000000;
  line-height: 1.6;
}

.interestsSection {
  margin-bottom: 1.5rem;
}

.interestsGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
}

.interestTag {
  padding: 0.5rem 1rem;
  background: #f8fafc;
  border: 1.5px solid #e2e8f0;
  border-radius: 24px;
  font-size: 0.875rem;
  color: #000000;
}

.compatibilityScore {
  margin-bottom: 1.5rem;
}

.compatibilityScore span {
  display: block;
  font-size: 0.875rem;
  color: #000000;
  margin-bottom: 0.5rem;
}

.scoreBar {
  height: 4px;
  background: #e2e8f0;
  border-radius: 2px;
  overflow: hidden;
}

.scoreProgress {
  height: 100%;
  background: #1E90FF;
  border-radius: 2px;
  transition: width 0.3s ease;
}

.interactionButtons {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 1rem;
}

.dislikeButton, .likeButton {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.dislikeButton {
  background: #f1f5f9;
  color: #94a3b8;
}

.dislikeButton:hover {
  background: #e2e8f0;
  transform: scale(1.05);
}

.likeButton {
  background: #1E90FF;
  color: white;
}

.likeButton:hover {
  background: #1873CC;
  transform: scale(1.05);
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.loadingSpinner {
  animation: spin 1s linear infinite;
  width: 32px;
  height: 32px;
  border: 3px solid #e2e8f0;
  border-top-color: #000000;
  border-radius: 50%;
}

.noProfilesContainer {
  text-align: center;
  padding: 4rem 1rem;
  color: #000000;
}

.noProfilesContainer h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #000000;
  margin-bottom: 0.5rem;
}

.topNavbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  z-index: 50;
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

.topNavContent {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topNavTitle {
  font-family: 'Magnolia Script', sans-serif;
  font-size: 1.5rem;
  color: #000000;
  flex-grow: 1;
}

.topNavActions {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.settingsDropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  width: 200px;
  z-index: 20;
}

.logoutButton {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  background: none;
  border: none;
  padding: 0.75rem 1rem;
  text-align: left;
  cursor: pointer;
  border-radius: 8px;
  font-size: 0.9375rem;
  color: #000000;
  transition: background-color 0.2s ease;
}

.logoutButton:hover {
  background-color: #f1f5f9;
}

.logoutButton svg {
  color: #ef4444;
}

.filterModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.filterModalContent {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  max-height: 80%;
  overflow-y: auto;
}

.filterSection {
  margin-bottom: 20px;
}

.filterSection label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: #000000;
}

.ageRangeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ageRangeContainer input {
  width: 100%;
  margin: 10px 0;
}

.interestsGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.interestChip {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  background: #f0f0f0;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #000000;
}

.selectedInterest {
  background: #1E90FF;
  color: white;
  border-color: #1E90FF;
}

.filterModalActions {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.applyFiltersButton {
  padding: 10px 20px;
  background: #1E90FF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.applyFiltersButton:hover {
  background: #1873CC;
}

.profileImagesCarousel {
  position: relative;
  overflow: hidden;
  touch-action: pan-y;
  -webkit-user-select: none;
  user-select: none;
}

.imageProgressContainer {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 10;
  gap: 6px;
}

.progressDot {
  width: 8px;
  height: 8px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  transition: all 0.3s ease;
}

.activeDot {
  background-color: white;
}

.errorBanner {
  display: flex;
  align-items: center;
  background-color: #ff4444;
  color: white;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 15px;
}

.errorMessage {
  margin-left: 10px;
}

.refreshButton {
  background-color: #1E90FF;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.refreshButton:hover {
  background-color: #1873CC;
}

@media screen and (max-width: 600px) {
  .discoverWrapper {
    padding: 70px 0 80px;
  }

  .profileCard {
    border-radius: 0;
    max-width: 100%;
  }

  .profileCarouselImage {
    height: 70vh;
    max-height: 500px;
  }

  .profileDetails {
    flex-direction: column;
    gap: 0.75rem;
  }

  .interactionButtons {
    gap: 1.5rem;
  }

  .dislikeButton, .likeButton {
    width: 56px;
    height: 56px;
  }
}

@media screen and (max-width: 900px) and (max-height: 500px) {
  .profileCarouselImage {
    height: 80vh;
  }
}

@supports(padding: max(0px)) {
  .discoverWrapper {
    padding-left: max(1rem, env(safe-area-inset-left));
    padding-right: max(1rem, env(safe-area-inset-right));
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
