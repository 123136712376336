.profileWrapper {
  min-height: 100vh;
  background-color: #f8fafc;
  margin-top: 60px;
  margin-bottom: 60px;
  padding: 2rem 1rem;
}

.profileContainer {
  width: 100%;
  max-width: 800px;
  background: #ffffff;
  border-radius: 24px;
  padding: 3rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  margin: 0 auto;
}

.profileHeader {
  text-align: center;
  margin-bottom: 3rem;
}

.profileHeader h2 {
  font-size: 2rem;
  font-weight: 700;
  color: #1e293b;
  margin-bottom: 0.5rem;
  letter-spacing: -0.025em;
}

.sectionHeader {
  margin-bottom: 1.5rem;
}

.sectionHeader h3 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 0.5rem;
}

.subtitle {
  color: #64748b;
  font-size: 1rem;
}

.profilePhotoSection {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
}

.profilePhotoContainer {
  text-align: center;
}

.profilePhoto {
  position: relative;
  width: 120px;
  height: 120px;
  margin: 0 auto;
}

.profilePhoto img,
.photoPlaceholder {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.photoPlaceholder {
  background: #1E90FF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  font-weight: 600;
  color: white;
}

.editPhotoButton {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 36px;
  height: 36px;
  background: #000000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 3px solid white;
  color: white;
  transition: all 0.2s ease;
  touch-action: manipulation;
}

.editPhotoButton:hover {
  background: #1c1c1c;
  transform: scale(1.05);
}

.photoHint {
  margin-top: 1rem;
  font-size: 0.875rem;
  color: #64748b;
}

.gallerySection {
  margin: 3rem 0;
  padding: 3rem 0;
  border-top: 1.5px solid #e2e8f0;
  border-bottom: 1.5px solid #e2e8f0;
}

.galleryGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1.5rem;
}

.galleryItem {
  position: relative;
  aspect-ratio: 1;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.galleryItem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.galleryItem:hover img {
  transform: scale(1.05);
}

.galleryUpload {
  aspect-ratio: 1;
  border: 2px dashed #e2e8f0;
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  background: #f8fafc;
  touch-action: manipulation;
}

.galleryUpload:hover {
  border-color: #1E90FF;
  background: #f1f5f9;
}

.uploadPlaceholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  color: #64748b;
}

.formSection {
  margin-top: 3rem;
}

.formGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.formGroup {
  margin-bottom: 1.5rem;
}

.formGroup label {
  display: block;
  font-size: 0.9375rem;
  font-weight: 500;
  color: #1e293b;
  margin-bottom: 0.5rem;
}

.input,
.textarea {
  width: 100%;
  padding: 0.875rem;
  border: 1.5px solid #e2e8f0;
  border-radius: 12px;
  background: #f8fafc;
  color: #1e293b;
  font-size: 16px;
  transition: all 0.2s ease;
  touch-action: manipulation;
}

.input:focus,
.textarea:focus {
  outline: none;
  border-color: #1E90FF;
  box-shadow: 0 0 0 3px rgba(30, 144, 255, 0.1);
}

.textarea {
  resize: vertical;
  min-height: 120px;
}

.interestsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-bottom: 1rem;
}

.interestTag {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.625rem 1rem;
  background: #f8fafc;
  border: 1.5px solid #e2e8f0;
  border-radius: 24px;
  font-size: 0.875rem;
  color: #1e293b;
  transition: all 0.2s ease;
}

.interestTag:hover {
  border-color: #1E90FF;
  background: #f1f5f9;
}

.removeInterest {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: #64748b;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;
  touch-action: manipulation;
}

.removeInterest:hover {
  color: #ef4444;
}

.addInterest {
  display: flex;
  gap: 0.75rem;
}

.addInterest input {
  flex: 1;
  padding: 0.625rem 1rem;
  border-radius: 24px;
  font-size: 16px;
  touch-action: manipulation;
}

.addButton {
  padding: 0.625rem 1.25rem;
  background: #1E90FF;
  color: white;
  border: none;
  border-radius: 24px;
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  touch-action: manipulation;
}

.addButton:hover {
  background: #1980FF;
}

.passwordSection {
  margin-top: 3rem;
  padding-top: 3rem;
  border-top: 1.5px solid #e2e8f0;
}

.passwordInputWrapper {
  position: relative;
}

.passwordToggleBtn {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: #64748b;
  font-size: 0.875rem;
  font-weight: 500;
  touch-action: manipulation;
}

.passwordToggleBtn:hover {
  color: #1e293b;
}

.profileActions {
  margin-top: 3rem;
  padding-top: 3rem;
  border-top: 1.5px solid #e2e8f0;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.editButton,
.saveButton {
  padding: 0.875rem 2rem;
  border-radius: 12px;
  font-size: 0.9375rem;
  font-weight: 600;
  cursor: pointer;
  background: #1E90FF;
  color: white;
  border: none;
  transition: all 0.2s ease;
  touch-action: manipulation;
}

.editButton:hover,
.saveButton:hover {
  background: #1980FF;
}

.cancelButton {
  padding: 0.875rem 2rem;
  border-radius: 12px;
  font-size: 0.9375rem;
  font-weight: 600;
  cursor: pointer;
  background: #f8fafc;
  color: #1e293b;
  border: 1.5px solid #e2e8f0;
  transition: all 0.2s ease;
  touch-action: manipulation;
}

.cancelButton:hover {
  background: #f8fafc;
  border-color: #64748b;
}

.editActions {
  display: flex;
  gap: 1rem;
}

.hiddenInput {
  display: none;
}

.removeImage {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  background: rgba(0, 0, 0, 0.6);
  border: none;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease;
  opacity: 0;
  touch-action: manipulation;
}

.galleryItem:hover .removeImage {
  opacity: 1;
}

.removeImage:hover {
  background: rgba(239, 68, 68, 0.9);
  transform: scale(1.05);
}

.buttonContent {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.spinnerSmall {
  animation: spin 1s linear infinite;
  width: 16px;
  height: 16px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #000000;
  border-radius: 50%;
}

.spinnerMedium {
  animation: spin 1s linear infinite;
  width: 24px;
  height: 24px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #000000;
  border-radius: 50%;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 50;
}

.loadingSpinner {
  animation: spin 1s linear infinite;
  width: 32px;
  height: 32px;
  border: 3px solid #e2e8f0;
  border-top-color: #000000;
  border-radius: 50%;
}

.galleryHint {
  color: #64748b;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.errorAlert {
  background-color: rgba(239, 68, 68, 0.1);
  color: #ef4444;
  padding: 1rem;
  border-radius: 12px;
  margin-bottom: 2rem;
}

.topNavbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  z-index: 1000;
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

.topNavContent {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topNavTitle {
  font-family: 'lineto-circular', sans-serif;
  font-size: 1.5rem;
  color: #1e293b;
  flex-grow: 1;
}

.topNavActions {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.settingsButton {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  color: #1e293b;
  transition: background-color 0.2s ease;
  border-radius: 50%;
  touch-action: manipulation;
}

.settingsButton:hover {
  background-color: #f1f5f9;
}

.settingsDropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  width: 200px;
  z-index: 20;
}

.logoutButton {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  background: none;
  border: none;
  padding: 0.75rem 1rem;
  text-align: left;
  cursor: pointer;
  border-radius: 8px;
  font-size: 0.9375rem;
  color: #1e293b;
  transition: background-color 0.2s ease;
  touch-action: manipulation;
}

.logoutButton:hover {
  background-color: #f1f5f9;
}

.logoutButton svg {
  color: #ef4444;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 576px) {
  .profileContainer {
    padding: 2.5rem;
  }
  
  .galleryGrid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 768px) {
  .profileContainer {
    padding: 3rem;
    border-radius: 24px;
  }
  
  .formGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }
  
  .galleryGrid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 992px) {
  .profileContainer {
    max-width: 900px;
  }
  
  .galleryGrid {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media screen and (min-width: 1200px) {
  .profileContainer {
    max-width: 1000px;
  }
}

@media screen and (max-width: 576px) {
  .profileWrapper {
    padding: 1rem 0.5rem;
  }

  .profileContainer {
    padding: 1.5rem;
    border-radius: 16px;
  }

  .formGrid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .galleryGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .input, 
  .textarea,
  select {
    font-size: 16px;
    padding: 0.75rem;
  }

  .profileActions {
    flex-direction: column;
    align-items: stretch;
    gap: 1rem;
  }

  .editActions {
    flex-direction: column-reverse;
  }

  .editButton,
  .saveButton,
  .cancelButton {
    width: 100%;
    padding: 0.75rem 1.5rem;
  }
}

@media (pointer: coarse) {
  .input,
  .textarea,
  .addButton,
  .editButton,
  .saveButton,
  button {
    min-height: 44px;
    min-width: 44px;
    touch-action: manipulation;
  }
}

@supports(padding-left: env(safe-area-inset-left)) {
  .profileContainer {
    padding-left: max(1.5rem, env(safe-area-inset-left));
    padding-right: max(1.5rem, env(safe-area-inset-right));
  }

  .topNavbar {
    padding-left: max(1rem, env(safe-area-inset-left));
    padding-right: max(1rem, env(safe-area-inset-right));
  }
}

@media screen and (max-width: 768px) and (orientation: landscape) {
  .profileWrapper {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .profileContainer {
    padding: 1rem;
  }

  .formGrid {
    grid-template-columns: 1fr;
  }

  .galleryGrid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 2), 
       screen and (min-resolution: 192dpi) {
  .profilePhoto img,
  .galleryItem img {
    image-rendering: -webkit-optimize-contrast;
  }
}

@media print {
  .profileWrapper {
    background-color: white;
    margin: 0;
    padding: 0;
  }

  .topNavbar,
  .bottomNav,
  .editPhotoButton,
  .removeImage {
    display: none;
  }

  .profileContainer {
    box-shadow: none;
    border: 1px solid #e2e8f0;
  }
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation: none !important;
    transition: none !important;
  }
}
