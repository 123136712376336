.filterModalFullPage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  color: black;
  overflow: hidden;
}

.filterModalContent {
  background: white;
  width: 100%;
  max-width: 100%;
  height: 100%;
  padding: 20px;
  overflow-y: auto;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
}

.filterModalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.closeButton, .applyButton {
  background: none;
  border: none;
  color: black;
  cursor: pointer;
  font-size: 16px;
  padding: 10px;
  transition: color 0.3s ease;
}

.closeButton:hover, .applyButton:hover {
  color: #1E90FF;
}

.filterSection {
  margin-bottom: 25px;
}

.filterSection label {
  display: block;
  margin-bottom: 15px;
  font-weight: bold;
  color: black;
  font-size: 16px;
}

.ageRangeContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.rangeInputWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.rangeInputWrapper input[type="range"] {
  width: 100%;
  background: transparent;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  margin: 15px 0;
}

.rangeInputWrapper input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  background: #ddd;
  border-radius: 3px;
  cursor: pointer;
}

.rangeInputWrapper input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #1E90FF;
  border-radius: 50%;
  cursor: pointer;
  margin-top: -8px;
}

.rangeInputWrapper input[type="range"]::-moz-range-track {
  width: 100%;
  height: 5px;
  background: #ddd;
  border-radius: 3px;
  cursor: pointer;
}

.rangeInputWrapper input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #1E90FF;
  border-radius: 50%;
  cursor: pointer;
  border: none;
}

.interestsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
}

.interestChip {
  padding: 10px;
  border: 1px solid #ddd;
  background: #f4f4f4;
  color: black;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  font-size: 14px;
  user-select: none;
}

.interestChip:hover {
  background: #e0e0e0;
  border-color: #1E90FF;
}

.selectedInterest {
  background: #1E90FF;
  color: white;
  border-color: #1E90FF;
}

.selectedInterest:hover {
  background: #1886F0;
}

.distanceSliderContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Loading Animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loadingSpinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid black;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@media screen and (max-width: 480px) {
  .filterModalContent {
    padding: 15px;
  }

  .filterModalHeader {
    margin-bottom: 15px;
  }

  .closeButton, .applyButton {
    font-size: 14px;
    padding: 8px;
  }

  .filterSection label {
    font-size: 15px;
  }

  .interestsGrid {
    grid-template-columns: repeat(2, 1fr);
  }

  .interestChip {
    font-size: 12px;
    padding: 8px;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .filterModalContent {
    padding: 20px;
  }

  .interestsGrid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .filterModalContent {
    max-width: 700px;
    height: auto;
    max-height: 90%;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .interestsGrid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .filterModalContent {
    max-width: 800px;
    height: auto;
    max-height: 85%;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
  }

  .interestsGrid {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media screen and (min-width: 1441px) {
  .filterModalContent {
    max-width: 1000px;
    height: auto;
    max-height: 80%;
    border-radius: 20px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  .interestsGrid {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media screen and (orientation: landscape) {
  .filterModalContent {
    height: 90%;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 2), 
       screen and (min-resolution: 192dpi) {
  .rangeInputWrapper input[type="range"]::-webkit-slider-thumb {
    width: 24px;
    height: 24px;
  }

  .rangeInputWrapper input[type="range"]::-moz-range-thumb {
    width: 24px;
    height: 24px;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  * {
    transition: none !important;
  }
}

@media print {
  .filterModalFullPage {
    position: static;
    background: white;
  }

  .filterModalContent {
    max-width: 100%;
    height: auto;
    box-shadow: none;
  }
}
