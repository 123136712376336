.chatsManagement {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.chatTable {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.chatTable th, 
.chatTable td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.chatTable th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.userInfo {
  display: flex;
  flex-direction: column;
}

.userInfo small {
  color: #666;
  font-size: 0.8em;
}

.deleteButton {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.deleteButton:hover {
  background-color: #ff3333;
}

.deleteButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.errorContainer {
  color: #d9534f;
  padding: 20px;
  background-color: #f2dede;
  border-radius: 5px;
  margin: 20px 0;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 300px;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

.chatStats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  color: #555;
}

.paginationControls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
}

.paginationControls button {
  padding: 8px 16px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
}

.paginationControls button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}