/* Base container */
.matchesManagement {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.matchesManagement h2 {
  color: #333;
  margin-bottom: 20px;
}

/* Table styles */
.matchTable {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.matchTable th, 
.matchTable td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.matchTable th {
  background-color: #f2f2f2;
  font-weight: bold;
}

/* User info styles */
.userInfo {
  display: flex;
  flex-direction: column;
}

.userInfo small {
  color: #666;
  font-size: 0.8em;
}

/* Button styles */
.deleteButton,
.editButton,
.createButton {
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.deleteButton {
  background-color: #ff4d4d;
  color: white;
}

.deleteButton:hover {
  background-color: #ff3333;
}

.deleteButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.editButton {
  background-color: #4a90e2;
  color: white;
  margin-right: 8px;
}

.editButton:hover {
  background-color: #357abd;
}

.createButton {
  background-color: #28a745;
  color: white;
  margin-bottom: 15px;
  padding: 8px 16px;
}

.createButton:hover {
  background-color: #218838;
}

/* Create match form */
.createMatchForm {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
}

.createMatchForm select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  width: 100%;
}

.createMatchForm button {
  padding: 8px 16px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.createMatchForm button:hover {
  background-color: #218838;
}

/* Error container */
.errorContainer {
  color: #d9534f;
  padding: 20px;
  background-color: #f2dede;
  border-radius: 5px;
  margin: 20px 0;
}

.errorContainer h2 {
  color: #d9534f;
  margin-bottom: 10px;
}

.errorContainer details {
  margin-top: 15px;
}

.errorContainer summary {
  cursor: pointer;
  color: #666;
  margin-bottom: 8px;
}

.errorContainer ul {
  margin-left: 20px;
  color: #666;
}

/* Loading container */
.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 300px;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

/* Match stats */
.matchStats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  color: #555;
}

/* Pagination controls */
.paginationControls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
}

.paginationControls button {
  padding: 8px 16px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
}

.paginationControls button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

/* Status and type selects */
.matchTable select {
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  min-width: 100px;
}

/* No matches message */
.noMatchesContainer {
  text-align: center;
  padding: 40px;
  color: #666;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .matchTable {
    display: block;
    overflow-x: auto;
  }

  .createMatchForm {
    grid-template-columns: 1fr;
  }

  .matchStats {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}