.loginPage {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  background: linear-gradient(135deg, #f5f7fa 0%, #e9ecef 100%);
  font-family: 'Inter', sans-serif;
  padding: 20px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loginContainer {
  position: relative;
  width: 100%;
  max-width: 1200px;
  height: 90vh;
  max-height: 800px;
  display: flex;
  overflow: hidden;
  border-radius: 24px;
  -webkit-box-shadow: 0 30px 60px rgba(0, 0, 0, 0.1);
          box-shadow: 0 30px 60px rgba(0, 0, 0, 0.1);
}

.loginBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 60%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
}

.backgroundImage {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  -o-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.loginOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(30, 144, 255, 0.05);
  z-index: 2;
}

.loginContent {
  position: absolute;
  left: 60%;
  width: 40%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px;
  z-index: 3;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.topRightLogo {
  position: absolute;
  top: -10px;
  left: -10px;
  margin-bottom: 30px;
  z-index: 4;
}

.topRightLogoImage {
  max-height: 80px;
  max-width: 200px;
  -o-object-fit: contain;
     object-fit: contain;
}

.loginHeader {
  text-align: center;
  margin-bottom: 40px;
}

.loginHeader h1 {
  font-size: 2.5rem;
  color: #1e90ff;
  margin-bottom: 10px;
  font-weight: 700;
}

.loginHeader p {
  color: #6c757d;
  font-size: 1rem;
}

.errorBanner {
  background-color: #ffebee;
  color: #d32f2f;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 30px;
}

.loginForm {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.inputGroup {
  position: relative;
}

.inputWrapper {
  display: flex;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 0 15px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background: white;
}

.inputWrapper:focus-within {
  border-color: #1e90ff;
  -webkit-box-shadow: 0 0 0 4px rgba(30, 144, 255, 0.1);
          box-shadow: 0 0 0 4px rgba(30, 144, 255, 0.1);
}

.inputIcon {
  color: #6c757d;
  margin-right: 15px;
}

.inputWrapper input {
  flex-grow: 1;
  height: 50px;
  border: none;
  font-size: 16px;
  color: #1D2144;
  background: white;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.inputWrapper input:focus {
  outline: none;
}

.passwordToggle {
  background: none;
  border: none;
  color: #6c757d;
  cursor: pointer;
  padding: 0;
}

.forgotPassword {
  text-align: right;
}

.forgotPassword a {
  color: #1e90ff;
  text-decoration: none;
  font-size: 0.9rem;
  -webkit-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.forgotPassword a:hover {
  color: #0056b3;
}

.submitButton {
  background: linear-gradient(135deg, #1e90ff 0%, #4a6cf7 100%);
  color: white;
  border: none;
  padding: 15px;
  border-radius: 12px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.submitButton:hover {
  -webkit-transform: translateY(-3px);
      -ms-transform: translateY(-3px);
          transform: translateY(-3px);
  -webkit-box-shadow: 0 10px 20px rgba(30, 144, 255, 0.3);
          box-shadow: 0 10px 20px rgba(30, 144, 255, 0.3);
}

.submitButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.signupLink {
  margin-top: 30px;
  text-align: center;
}

.signupLink p {
  color: #6c757d;
  margin-bottom: 10px;
}

.signupLink a {
  color: #1e90ff;
  text-decoration: none;
  font-weight: 600;
  -webkit-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.signupLink a:hover {
  color: #0056b3;
}

@media only screen and (max-width: 1024px) {
  .loginPage {
    background: url('../assets/login_bg_mobile.png') no-repeat center center fixed;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    padding: 0;
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .loginContainer {
    max-width: 100%;
    height: 100vh;
    max-height: none;
    -webkit-box-shadow: none;
            box-shadow: none;
    border-radius: 0;
    position: relative;
    background: transparent;
    overflow: hidden;
  }

  .loginBackground {
    display: none;
  }

  .loginContent {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 40px 20px;
    justify-content: center;
    background: rgba(255, 255, 255, 0.8);
    z-index: 3;
    -webkit-box-shadow: 0 0 30px rgba(0,0,0,0.1);
            box-shadow: 0 0 30px rgba(0,0,0,0.1);
    overflow-y: auto;
  }

  .topRightLogo {
    position: absolute;
    top: -5px;
    left: -5px;
    z-index: 10;
  }

  .topRightLogoImage {
    max-height: 70px;
    max-width: 180px;
    -o-object-fit: contain;
       object-fit: contain;
  }

  .inputWrapper {
    background: white;
    border: 1px solid rgba(30, 144, 255, 0.2);
    -webkit-box-shadow: 0 4px 6px rgba(0,0,0,0.05);
            box-shadow: 0 4px 6px rgba(0,0,0,0.05);
  }

  .inputWrapper input {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .loginContent {
    padding: 30px 15px;
  }

  .loginHeader h1 {
    font-size: 2rem;
  }

  .inputWrapper input {
    font-size: 16px;
  }

  .topRightLogo {
    top: -3px;
    left: -3px;
  }

  .topRightLogoImage {
    max-height: 60px;
    max-width: 160px;
  }
}