.signupPage {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  height: 100%;
  background: -webkit-linear-gradient(135deg, #f5f7fa 0%, #e9ecef 100%);
  background: -moz-linear-gradient(135deg, #f5f7fa 0%, #e9ecef 100%);
  background: linear-gradient(135deg, #f5f7fa 0%, #e9ecef 100%);
  font-family: 'Inter', sans-serif;
  padding: 20px;
  position: relative;
  overflow: hidden;
}

.signupContainer {
  position: relative;
  width: 100%;
  max-width: 1200px;
  height: 90vh;
  max-height: 800px;
  display: flex;
  overflow: hidden;
  border-radius: 24px;
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.1);
}

.signupBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 60%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
}

.backgroundImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: transform 0.5s ease;
}

.signupOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(30, 144, 255, 0.05);
  z-index: 2;
}

.signupContent {
  position: absolute;
  left: 60%;
  width: 40%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px;
  z-index: 3;
  overflow-y: auto;
}

.topRightLogo {
  position: absolute;
  top: -10px;
  left: -10px;
  margin-bottom: 30px;
  z-index: 4;
}

.topRightLogoImage {
  max-height: 80px;
  max-width: 200px;
  object-fit: contain;
}

.signupHeader {
  text-align: center;
  margin-bottom: 40px;
}

.signupHeader h1 {
  font-size: 2.5rem;
  color: #1e90ff;
  margin-bottom: 10px;
  font-weight: 700;
}

.signupHeader p {
  color: #6c757d;
  font-size: 1rem;
}

.errorBanner {
  background-color: #fee2e2;
  border: 1px solid #fecaca;
  color: #dc2626;
  padding: 12px 16px;
  border-radius: 4px;
  margin-bottom: 20px;
  font-size: 14px;
  text-align: center;
}

.signupForm {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.inputGroup {
  position: relative;
}

.inputWrapper {
  display: flex;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 0 15px;
  transition: all 0.3s ease;
  background: white;
}

.inputWrapper:focus-within {
  border-color: #1e90ff;
  box-shadow: 0 0 0 4px rgba(30, 144, 255, 0.1);
}

.inputIcon {
  color: #6c757d;
  margin-right: 15px;
}

.inputWrapper input {
  flex-grow: 1;
  height: 50px;
  border: none;
  font-size: 16px;
  color: #1D2144;
  background: white;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

.inputWrapper input:focus {
  outline: none;
}

.passwordToggle {
  background: none;
  border: none;
  color: #6c757d;
  cursor: pointer;
}

.genderGroup {
  display: flex;
  gap: 15px;
  width: 100%;
}

.genderOption {
  flex: 1;
  position: relative;
  cursor: pointer;
}

.genderOption input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.genderOption span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  background: white;
  transition: all 0.3s ease;
}

.genderOption input:checked + span {
  background-color: #1e90ff;
  color: white;
  border-color: #1e90ff;
}

.genderOption:hover span {
  border-color: #1e90ff;
}

.submitButton {
  background: -webkit-linear-gradient(135deg, #1e90ff 0%, #4a6cf7 100%);
  background: -moz-linear-gradient(135deg, #1e90ff 0%, #4a6cf7 100%);
  background: linear-gradient(135deg, #1e90ff 0%, #4a6cf7 100%);
  color: white;
  border: none;
  padding: 15px;
  border-radius: 12px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submitButton:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(30, 144, 255, 0.3);
}

.submitButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.loginLink {
  margin-top: 30px;
  text-align: center;
}

.loginLink p {
  color: #6c757d;
  margin-bottom: 10px;
}

.loginLink a {
  color: #1e90ff;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
}

.loginLink a:hover {
  color: #0056b3;
}

.verificationBanner {
  background-color: #dcfce7;
  border: 1px solid #86efac;
  color: #166534;
  padding: 12px 16px;
  border-radius: 4px;
  margin-bottom: 20px;
  font-size: 14px;
  text-align: center;
}

@media only screen and (max-width: 1024px) {
  .signupPage {
    background: url('../assets/login_bg_mobile.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding: 0;
    height: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: auto;
  }

  .signupContainer {
    max-width: 100%;
    height: 100vh;
    max-height: none;
    box-shadow: none;
    border-radius: 0;
    position: relative;
    background: transparent;
  }

  .signupBackground {
    display: none;
  }

  .signupContent {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 40px 20px;
    justify-content: center;
    background: rgba(255, 255, 255, 0.8);
    z-index: 3;
    box-shadow: 0 0 30px rgba(0,0,0,0.1);
    overflow-y: auto;
  }

  .topRightLogo {
    position: absolute;
    top: -5px;
    left: -5px;
    z-index: 10;
  }

  .topRightLogoImage {
    max-height: 70px;
    max-width: 180px;
  }

  .genderGroup {
    flex-direction: row;
    gap: 10px;
  }

  .submitButton {
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .signupContent {
    padding: 30px 15px;
  }

  .signupHeader h1 {
    font-size: 2rem;
  }

  .topRightLogo {
    top: -3px;
    left: -3px;
  }

  .topRightLogoImage {
    max-height: 60px;
    max-width: 160px;
  }
}