.forYouWrapper {
  min-height: 100vh;
  background-color: #ffffff;
  padding: 0;
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
}

.sectionContainer {
  width: 100%;
  padding: 0;
}

.sectionTitle {
  font-size: 1.25rem;
  font-weight: 700;
  color: #000000;
  margin: 1rem;
}

.cardsSlider {
  display: flex;
  gap: 0.75rem;
  overflow-x: auto;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 0 0.75rem;
  width: 100%;
  height: calc(100vh - 12.5rem);
  align-items: center;
}

.cardsSlider::-webkit-scrollbar {
  display: none;
}

.profileCard {
  flex: 0 0 calc(100vw - 1.5rem);
  max-width: calc(100vw - 1.5rem);
  height: calc(100vh - 14rem);
  position: relative;
  border-radius: 1.25rem;
  overflow: hidden;
  box-shadow: 0 4px 6px -1px rgba(30, 144, 255, 0.1),
              0 2px 4px -1px rgba(30, 144, 255, 0.06);
  transition: all 0.4s ease;
  scroll-snap-align: center;
  scroll-snap-stop: always;
}

.profileCard:focus-within,
.profileCard.focused {
  box-shadow: 0 10px 20px -5px rgba(30, 144, 255, 0.2),
              0 5px 10px -5px rgba(30, 144, 255, 0.1);
  z-index: 1;
}

.imageContainer {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.profileImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: transform 0.4s ease;
}

.profileCard:focus-within .profileImage,
.profileCard.focused .profileImage {
  transform: scale(1.02);
}

.profileInfo {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2rem 1.25rem 1.25rem;
  background: linear-gradient(to top, 
    rgba(30, 144, 255, 0.8) 0%,
    rgba(30, 144, 255, 0.7) 40%,
    rgba(30, 144, 255, 0) 100%);
  color: white;
  z-index: 2;
  transition: transform 0.4s ease;
}

.profileCard:focus-within .profileInfo,
.profileCard.focused .profileInfo {
  transform: translateY(0);
}

.profileName {
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
  color: white;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  transition: transform 0.4s ease;
}

.profileCard:focus-within .profileName,
.profileCard.focused .profileName {
  transform: scale(1.02);
}

.profileDetails {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.detailItem {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: rgba(255, 255, 255, 0.9);
  font-size: 1rem;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.compatibilityScore {
  margin-top: 1rem;
}

.compatibilityScore span {
  display: block;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 0.25rem;
}

.scoreBar {
  height: 4px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 2px;
  overflow: hidden;
}

.scoreProgress {
  height: 100%;
  background: white;
  border-radius: 2px;
  transition: width 0.3s ease;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.loadingSpinner {
  animation: spin 1s linear infinite;
  width: 32px;
  height: 32px;
  border: 3px solid #e2e8f0;
  border-top-color: #000000;
  border-radius: 50%;
}

.noRecommendations {
  width: 100%;
  text-align: center;
  padding: 2rem;
  color: #000000;
}

.refreshButton {
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  background: #1E90FF;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.refreshButton:hover {
  background: #1777d6;
}

.errorBanner {
  background: #fee2e2;
  color: #dc2626;
  padding: 0.75rem;
  border-radius: 8px;
  margin: 0 0.75rem 1rem;
}

.topNavbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(30, 144, 255, 0.1);
  z-index: 50;
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

.topNavContent {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topNavTitle {
  font-family: 'lineto-circular', sans-serif;
  font-size: 1.5rem;
  color: #000000;
  flex-grow: 1;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Media Queries */
@media screen and (max-width: 280px) {
  .profileCard {
    height: calc(100vh - 15rem);
  }
  
  .profileName {
    font-size: 1.25rem;
  }
}

@media screen and (min-width: 768px) {
  .cardsSlider {
    padding: 0 1rem;
    gap: 1rem;
  }
  
  .profileCard {
    flex: 0 0 calc(50% - 1rem);
    max-width: calc(50% - 1rem);
    height: calc(100vh - 15rem);
  }
}

@media screen and (min-width: 1024px) {
  .profileCard {
    flex: 0 0 calc(33.33% - 1rem);
    max-width: calc(33.33% - 1rem);
    height: calc(100vh - 15rem);
  }
}

@media screen and (min-width: 1200px) {
  .profileCard {
    flex: 0 0 calc(25% - 1rem);
    max-width: calc(25% - 1rem);
    height: calc(100vh - 15rem);
  }
}

@media screen and (orientation: landscape) and (max-width: 767px) {
  .profileCard {
    height: calc(100vh - 10rem);
  }
  
  .cardsSlider {
    height: calc(100vh - 8.5rem);
  }
}

@media (pointer: coarse) {
  .refreshButton {
    min-height: 44px;
    padding: 0.5rem 1.5rem;
  }
}

@supports(padding: max(0px)) {
  .forYouWrapper {
    padding-left: max(0.75rem, env(safe-area-inset-left));
    padding-right: max(0.75rem, env(safe-area-inset-right));
  }
}

@media screen and (device-width: 375px) and (device-height: 812px) {
  .forYouWrapper {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  
  .profileCard {
    height: calc(100vh - 16rem);
  }
  
  .cardsSlider {
    height: calc(100vh - 14.5rem);
  }
}
