.chatLayout {
  width: 100%;
  height: 100vh;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.chatFixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  transition: height 0.3s ease;
}

.chatFixed.keyboardVisible {
  height: 100%;
  position: absolute;
}

.chatHeader {
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  position: relative;
  z-index: 10;
  height: 60px;
  flex-shrink: 0;
}

.headerContent {
  height: 100%;
  padding: 0 1.25rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.backButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  color: #000000;
  background: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
  -webkit-tap-highlight-color: transparent;
}

.backButton:hover {
  background-color: #f0f0f0;
}

.chatUserInfo {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  flex: 1;
}

.chatUserAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #1E90FF;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: 600;
}

.chatUserDetails {
  display: flex;
  flex-direction: column;
}

.chatUserDetails h2 {
  font-size: 1rem;
  font-weight: 600;
  color: #000000;
  margin: 0;
}

.chatUserDetails span {
  font-size: 0.875rem;
  color: #666666;
}

.chatOptionsButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  color: #000000;
  background: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
  -webkit-tap-highlight-color: transparent;
}

.chatOptionsButton:hover {
  background-color: #f0f0f0;
}

.chatMessages {
  flex: 1;
  min-height: 0;
  overflow-y: auto;
  padding: 1.25rem;
  background-color: transparent;
  position: relative;
  -webkit-overflow-scrolling: touch;
}

.messageContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.messageBubble {
  max-width: 70%;
  padding: 0.75rem 1rem;
  border-radius: 12px;
  position: relative;
}

.messageBubble.sent {
  align-self: flex-end;
  background-color: #1E90FF;
  color: #ffffff;
}

.messageBubble.received {
  align-self: flex-start;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #e0e0e0;
}

.messageContent {
  margin-bottom: 0.25rem;
  word-break: break-word;
}

.messageMeta {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.75rem;
}

.messageBubble.sent .messageMeta {
  color: rgba(255, 255, 255, 0.7);
  justify-content: flex-end;
}

.messageBubble.received .messageMeta {
  color: #666666;
}

.messageStatus {
  display: flex;
  align-items: center;
}

.typingIndicator {
  align-self: flex-start;
  background-color: #ffffff;
  padding: 0.75rem 1rem;
  border-radius: 12px;
  display: flex;
  gap: 0.25rem;
}

.typingDot {
  width: 8px;
  height: 8px;
  background-color: #000000;
  border-radius: 50%;
}

.typingDot:nth-child(1) { animation: typing 1s infinite; }
.typingDot:nth-child(2) { animation: typing 1s infinite 0.2s; }
.typingDot:nth-child(3) { animation: typing 1s infinite 0.4s; }

@keyframes typing {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-4px); }
}

.chatInputContainer {
  flex-shrink: 0;
  min-height: 72px;
  padding: 1rem 1.25rem;
  background-color: #ffffff;
  border-top: 1px solid #e0e0e0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 1;
}

.chatInputForm {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
}

.chatInput {
  flex: 1;
  height: 44px;
  padding: 0 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 22px;
  background-color: #f5f5f5;
  font-size: 16px;
  color: #000000;
  transition: all 0.2s;
  -webkit-appearance: none;
  appearance: none;
}

.chatInput:focus {
  outline: none;
  border-color: #1E90FF;
  background-color: #ffffff;
  box-shadow: 0 0 0 2px rgba(30, 144, 255, 0.1);
}

.chatInput:disabled {
  background-color: #f0f0f0;
  cursor: not-allowed;
}

.sendButton {
  width: 44px;
  min-width: 44px;
  height: 44px;
  border-radius: 22px;
  background-color: #1E90FF;
  color: #ffffff;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}

.sendButton:hover:not(:disabled) {
  background-color: #1873CC;
  transform: scale(1.05);
}

.sendButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.sendButton svg {
  width: 20px;
  height: 20px;
}

.errorBanner {
  background-color: #fee2e2;
  border-left: 4px solid #ef4444;
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.errorBanner svg {
  color: #ef4444;
  flex-shrink: 0;
}

.errorMessage {
  color: #b91c1c;
  font-size: 0.875rem;
}

.textCenter {
  text-align: center;
}

.mt2 {
  margin-top: 0.5rem;
}

.animateSpin {
  animation: spin 1s linear infinite;
  color: #000000;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Mobile-specific styles */
@media screen and (max-width: 640px) {
  .headerContent {
    padding: 0 1rem;
  }

  .chatMessages {
    padding: 1rem;
  }

  .messageBubble {
    max-width: 85%;
    padding: 0.625rem 0.875rem;
  }

  .chatInputContainer {
    padding: 0.75rem 1rem;
  }

  .chatInput {
    font-size: 16px;
    padding: 0 0.875rem;
  }

  .sendButton svg {
    width: 18px;
    height: 18px;
  }
}

/* iOS-specific fixes */
@supports (-webkit-touch-callout: none) {
  .chatLayout {
    height: -webkit-fill-available;
  }
  
  body.keyboard-visible {
    position: fixed;
    width: 100%;
    height: 100%;
  }
}

/* Handle safe area insets */
@supports (padding: env(safe-area-inset-bottom)) {
  .chatLayout {
    padding-bottom: env(safe-area-inset-bottom);
  }
  
  .chatInputContainer {
    padding-bottom: calc(1rem + env(safe-area-inset-bottom));
  }
}
